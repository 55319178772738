export const instruments = [
  { name: "Yamaha Grand Piano", channel: 0 },
  { name: "Bright Yamaha Grand", channel: 1 },
  { name: "Electric Piano", channel: 2 },
  { name: "Honky Tonk", channel: 3 },
  { name: "Rhodes EP", channel: 4 },
  { name: "Legend EP 2", channel: 5 },
  { name: "Harpsichord", channel: 6 },
  { name: "Clavinet", channel: 7 },
  { name: "Celesta", channel: 8 },
  { name: "Glockenspiel", channel: 9 },
  { name: "Music Box", channel: 10 },
  { name: "Vibraphone", channel: 11 },
  { name: "Marimba", channel: 12 },
  { name: "Xylophone", channel: 13 },
  { name: "Tubular Bells", channel: 14 },
  { name: "Dulcimer", channel: 15 },
  { name: "DrawbarOrgan", channel: 16 },
  { name: "Percussive Organ", channel: 17 },
  { name: "Rock Organ", channel: 18 },
  { name: "Church Organ", channel: 19 },
  { name: "Reed Organ", channel: 20 },
  { name: "Accordian", channel: 21 },
  { name: "Harmonica", channel: 22 },
  { name: "Bandoneon", channel: 23 },
  { name: "Nylon String Guitar", channel: 24 },
  { name: "Steel String Guitar", channel: 25 },
  { name: "Jazz Guitar", channel: 26 },
  { name: "Clean Guitar", channel: 27 },
  { name: "Palm Muted Guitar", channel: 28 },
  { name: "Overdrive Guitar", channel: 29 },
  { name: "Distortion Guitar", channel: 30 },
  { name: "Guitar Harmonics", channel: 31 },
  { name: "Acoustic Bass", channel: 32 },
  { name: "Fingered Bass", channel: 33 },
  { name: "Picked Bass", channel: 34 },
  { name: "Fretless Bass", channel: 35 },
  { name: "Slap Bass", channel: 36 },
  { name: "Pop Bass", channel: 37 },
  { name: "Synth Bass 1", channel: 38 },
  { name: "Synth Bass 2", channel: 39 },
  { name: "Violin", channel: 40 },
  { name: "Viola", channel: 41 },
  { name: "Cello", channel: 42 },
  { name: "Contrabass", channel: 43 },
  { name: "Tremolo", channel: 44 },
  { name: "Pizzicato Section", channel: 45 },
  { name: "Harp", channel: 46 },
  { name: "Timpani", channel: 47 },
  { name: "Strings", channel: 48 },
  { name: "Slow Strings", channel: 49 },
  { name: "Synth Strings 1", channel: 50 },
  { name: "Synth Strings 2", channel: 51 },
  { name: "Ahh Choir", channel: 52 },
  { name: "Ohh Voices", channel: 53 },
  { name: "Synth Voice", channel: 54 },
  { name: "Orchestra Hit", channel: 55 },
  { name: "Trumpet", channel: 56 },
  { name: "Trombone", channel: 57 },
  { name: "Tuba", channel: 58 },
  { name: "Muted Trumpet", channel: 59 },
  { name: "French Horns", channel: 60 },
  { name: "Brass Section", channel: 61 },
  { name: "Synth Brass 1", channel: 62 },
  { name: "Synth Brass 2", channel: 63 },
  { name: "Soprano Sax", channel: 64 },
  { name: "Alto Sax", channel: 65 },
  { name: "Tenor Sax", channel: 66 },
  { name: "Baritone Sax", channel: 67 },
  { name: "Oboe", channel: 68 },
  { name: "English Horn", channel: 69 },
  { name: "Bassoon", channel: 70 },
  { name: "Clarinet", channel: 71 },
  { name: "Piccolo", channel: 72 },
  { name: "Flute", channel: 73 },
  { name: "Recorder", channel: 74 },
  { name: "Pan Flute", channel: 75 },
  { name: "Bottle Chiff", channel: 76 },
  { name: "Shakuhachi", channel: 77 },
  { name: "Whistle", channel: 78 },
  { name: "Ocarina", channel: 79 },
  { name: "Square Lead", channel: 80 },
  { name: "Saw Wave", channel: 81 },
  { name: "Calliope Lead", channel: 82 },
  { name: "Chiffer Lead", channel: 83 },
  { name: "Charang", channel: 84 },
  { name: "Solo Vox", channel: 85 },
  { name: "Fifth Sawtooth Wave", channel: 86 },
  { name: "Bass & Lead", channel: 87 },
  { name: "Fantasia", channel: 88 },
  { name: "Warm Pad", channel: 89 },
  { name: "Polysynth", channel: 90 },
  { name: "Space Voice", channel: 91 },
  { name: "Bowed Glass", channel: 92 },
  { name: "Metal Pad", channel: 93 },
  { name: "Halo Pad", channel: 94 },
  { name: "Sweep Pad", channel: 95 },
  { name: "Ice Rain", channel: 96 },
  { name: "Soundtrack", channel: 97 },
  { name: "Crystal", channel: 98 },
  { name: "Atmosphere", channel: 99 },
  { name: "Brightness", channel: 100 },
  { name: "Goblin", channel: 101 },
  { name: "Echo Drops", channel: 102 },
  { name: "Star Theme", channel: 103 },
  { name: "Sitar", channel: 104 },
  { name: "Banjo", channel: 105 },
  { name: "Shamisen", channel: 106 },
  { name: "Koto", channel: 107 },
  { name: "Kalimba", channel: 108 },
  { name: "BagPipe", channel: 109 },
  { name: "Fiddle", channel: 110 },
  { name: "Shenai", channel: 111 },
  { name: "Tinker Bell", channel: 112 },
  { name: "Agogo", channel: 113 },
  { name: "Steel Drums", channel: 114 },
  { name: "Woodblock", channel: 115 },
  { name: "Taiko Drum", channel: 116 },
  { name: "Melodic Tom", channel: 117 },
  { name: "Synth Drum", channel: 118 },
  { name: "Reverse Cymbal", channel: 119 },
  { name: "Fret Noise", channel: 120 },
  { name: "Breath Noise", channel: 121 },
  { name: "Sea Shore", channel: 122 },
  { name: "Bird Tweet", channel: 123 },
  { name: "Telephone", channel: 124 },
  { name: "Helicopter", channel: 125 },
  { name: "Applause", channel: 126 },
];
